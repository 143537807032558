import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import BlogPost from "../components/blogpost"
import SEO from "../components/seo"


const summarizeContent = ({ children }) => {
  let len = children.length;
  children.forEach((el, idx) => {
    if (el.type === "class" && el.className === "post-break")
      len = idx;
  })
  return {
    children: children.slice(0, len)
  }
}

const BlogPage = ({ data }) => {
  const blogPosts = data.gcms.blogPosts.edges;

  return (
    <Layout>
      <SEO title="Blog"/>
      {blogPosts.map((blogPostEdge) => {
        const blogPost = blogPostEdge.node;
        return (
          <BlogPost
            key={blogPost.slug}
            title={blogPost.title}
            slug={blogPost.slug}
            titleIsLink={true}
            content={summarizeContent(blogPost.body.raw)}
            endFragment={
              <Link
                className="text-black underline"
                to={`/blog/${blogPost.slug}`}
              >
                Continue reading...
              </Link>
            }
          />
        )
      })}
    </Layout>
  )
}

export const blogPageQuery = graphql`
{
  gcms {
    blogPosts: blogPostsConnection(orderBy: publishDate_DESC) {
      edges {
        node {
          title
          slug
          publishDate
          updateDate
          body {
            raw
          }
        }
      }
    }
  }
}
`
export default BlogPage
